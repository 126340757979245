export const DataManager = {
    _callbacks : [],
    getClientId : () => {
        return DataManager.getJsonItem("clientId");
    },
    setClientId : (clientId) => {
        return DataManager.setJsonItem("clientId", clientId);
    },
    getUser : () => {
        return DataManager.getJsonItem("user");
    },
    setUser : (user) => {
        return DataManager.setJsonItem("user", user);
    },
    getCurrentSite : () => {
        return DataManager.getJsonItem("site");
    },
    setCurrentSite : (site) => {
        return DataManager.setJsonItem("site", site);
    },
    getCurrentSubmission : () => {
        return DataManager.getJsonItem("auditSubmission");
    },
    setCurrentSubmission : (submission) => {
        return DataManager.setJsonItem("auditSubmission", submission);
    },
    getCurrentQuestions : () => {
        return DataManager.getJsonItem("auditQuestions");
    },
    setCurrentQuestions : (questions) => {
        return DataManager.setJsonItem("auditQuestions", questions);
    },
    setTemporaryAlertReadIds : (readIds) => {
        return DataManager.setJsonItem("temporaryAlertReadIds", readIds);
    },
    getTemporaryAlertReadIds : () => {
        let out = DataManager.getJsonItem("temporaryAlertReadIds");
        if (out != null) {
            return out;
        }
        return [];
    },
    getTemporaryUserName : () => {
        return DataManager.getJsonItem("temporaryUserName");
    },
    setTemporaryUserName : (name) => {
        return DataManager.setJsonItem("temporaryUserName", name);
    },
    getAppConfig : () => {
        return DataManager.getJsonItem("appConfig");
    },
    setAppConfig : (config) => {
        let result = DataManager.setJsonItem("appConfig", config);
        if (result) {
            // Components should define and release window._appConfigDidUpdate on mount/unmount
            if (window.hasOwnProperty("_appConfigDidUpdate")) {
                if (window._appConfigDidUpdate !== undefined && window._appConfigDidUpdate !== null) {
                    window._appConfigDidUpdate(config);
                }
            }
            return true;
        }
        return false;
    },
    clearSubmission : () => {
        localStorage.removeItem("auditSubmission");
        localStorage.removeItem("auditQuestions");
        return true
    },
    getJsonItem : (keyName) => {
        let item = localStorage.getItem(keyName);
        if (item != null) {
            item = JSON.parse(item);
            return item;
        }
        return null;
    },
    setJsonItem : (keyName, item) => {
        localStorage.setItem(keyName, JSON.stringify(item));
        DataManager._triggerCallbacks(keyName, item);
        return true;
    },
    clear : () => {
        let keys = [
            "user", "sessionToken", "site",
            "auditSubmission", "auditQuestions", "temporaryAlertReadIds",
            "temporaryUserName", "appConfig"
        ];
        for (let i = 0; i < keys.length; i++) {
            localStorage.removeItem(keys[i]);
        }
        return true;
    },
    addCallback : (callback) => {
        if (callback instanceof Function) {
            DataManager._callbacks.push(callback);
        }
    },
    removeCallback : (callback) => {
        for (let i = 0; i < DataManager._callbacks.length; i++) {
            if (DataManager._callbacks[i] === callback) {
                delete DataManager._callbacks[i];
                break;
            }
        }
    },
    _triggerCallbacks : (keyName, value) => {
        DataManager._callbacks.forEach((callback) => {
            callback(keyName, value);
        });
    },
    appConfigKeys : {
        Login : {
            LABEL_LOGIN_TEMPORARY : "login_temporary_login_label"
        },
        Home : {
            LABEL_ALERTS : "home_alerts_label",
            LABEL_CLEANING_CHECKS : "home_cleaning_checks_label",
            LABEL_INSPECTION_AUDIT : "home_inspection_audit_label",
            LABEL_CLEANING_SPEC : "home_cleaning_spec_label",
            LABEL_WASTE_PROCEDURES : "home_waste_procedures"
        }
    }
}