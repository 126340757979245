import React from 'react';
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import BaseComponent from "../../BaseComponent";
import LoadingSpinner from "../../common/LoadingSpinner";

import "./WasteProcedureListComponent.css";

import indicatorBlack from "../../../assets/europackaging/arrow_right-black.svg";
import ScreenTitleComponent from "../common/ScreenTitleComponent";
import {DataManager} from "../../../data/DataManager";
import {Chronos} from "../../../data/Chronos";
import TableComponent, {Column} from "../../common/TableComponent";

export default class WasteProcedureListComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            procNetworkInFlight : false,
            wasteProcedures : null,
            wasteNotifications : [],
            wasteSchedules : []
        })
    }

    componentDidMount() {
        this.getWasteProceduresFromNetwork();
        this.getWasteScheduleNotificationsFromNetwork();

        window._epShowSiteChangeButton(false);
    }

    componentWillUnmount() {
        window._epShowSiteChangeButton(true);
    }

    moveToDetailScreen = (wasteProcedureId) => {
        this.props.history.push("/waste-procedure/" + wasteProcedureId);
    }

    getWasteProceduresFromNetwork = () => {
        if (this.state.procNetworkInFlight) return;

        this.setState({
            procNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.wasteProcedure.getWasteProcedures)
            .then((r) => {
                let wasteProcedures = [];

                let resp = API.parse(r);
                if (resp.success) {
                    wasteProcedures = resp.data.wasteProcedures;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    procNetworkInFlight : false,
                    wasteProcedures
                });
            })
            .catch((e) => {
                console.log(e);
            })
    }

    getWasteScheduleNotificationsFromNetwork = () => {
        if (this.state.scheduleNotificationNetworkInFlight) return;
        this.setState({
            scheduleNotificationNetworkInFlight : true
        });

        let site = DataManager.getCurrentSite();

        let formData = new FormData();
        formData.append("siteId", site.id);

        Axios.post(ENDPOINTS.wasteProcedure.getSiteWasteScheduleNotifications, formData)
            .then((r) => {
                let wasteNotifications = [];
                let wasteSchedules = [];

                let resp = API.parse(r);
                if (resp.success) {
                    wasteNotifications = resp.data.notifications;
                    wasteSchedules = resp.data.schedules;
                } else {
                    console.log(API.formatError(resp));
                }

                this.setState({
                    scheduleNotificationNetworkInFlight : false,
                    wasteNotifications,
                    wasteSchedules
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    scheduleNotificationNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        this.props.showError("Error", message);
    }

    render() {
        let listItems = [];
        if (this.state.procNetworkInFlight) {
            listItems.push(
                <LoadingSpinner key={"loading"} />
            )
        } else {
            if (this.state.wasteProcedures != null && this.state.wasteProcedures.length > 0) {
                listItems.push(
                    <div className={"expandable-item expandable-title"}>
                        Waste Procedures
                    </div>
                )

                this.state.wasteProcedures.forEach((proc) => {
                    listItems.push(
                        <div key={"p" + proc.id} className={"expandable-item"} onClick={() => this.moveToDetailScreen(proc.id)}>
                            <div className={"title-text"}>{ proc.title }</div>
                            <div className={"indicator"} style={{backgroundImage : "url(" + indicatorBlack + ")"}} />
                        </div>
                    )
                });
            }
        }

        let notificationElems = [];
        this.state.wasteNotifications.forEach((notification) => {
            notificationElems.push(
                <div className={"row mt-2"}>
                    <div className={"hidden-xs col-md-3"} />
                    <div className={"col-6"}>
                        <div className="alert alert-secondary text-center">
                            Your <strong>{notification.label}</strong> waste is due for disposal on <strong>{Chronos.withTimestampSeconds(notification.date).format("dd.MM.yyyy")}</strong>
                        </div>
                    </div>
                </div>
            )
        });

        let scheduleTable = [];
        if (this.state.wasteSchedules.length > 0) {
            scheduleTable = (
                <div className={"row mt-2"}>
                    <div className={"hidden-xs col-md-2"} />
                    <div className={"col-12 col-md-8"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.wasteSchedules}>
                            <Column name={"equipmentName"} title={"Equipment"} />
                            <Column name={"name"} title={"Waste"} />
                            <Column name={"containerQuantity"} title={"Container Qty"} className={"text-center"} />
                            <Column name={"frequencyName"} title={"Frequency"} />
                            <Column name={"mon"} title={"Mon"} className={"text-center"} />
                            <Column name={"tue"} title={"Tue"} className={"text-center"} />
                            <Column name={"wed"} title={"Wed"} className={"text-center"} />
                            <Column name={"thu"} title={"Thu"} className={"text-center"} />
                            <Column name={"fri"} title={"Fri"} className={"text-center"} />
                            <Column name={"sat"} title={"Sat"} className={"text-center"} />
                            <Column name={"sun"} title={"Sun"} className={"text-center"} />
                            <Column name={"weeklyLiftQuantity"} title={"Weekly Lift Qty"} className={"text-center"} />
                        </TableComponent>
                    </div>
                </div>
            )
        }

        return (
            <div className={"container cleaning-specification-list-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={"Waste Procedures"} history={this.props.history} />
                    </div>
                </div>

                {notificationElems}

                {scheduleTable}

                <div className={"row mt-4"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <div className={"expandable-list"}>
                            {listItems}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}