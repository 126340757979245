import BaseComponent from "../../BaseComponent";
import React from "react";
import DatePicker from "react-datepicker";
import {Chronos} from "../../../data/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import "./WasteScheduleCollectionDateModal.css";

export default class WasteScheduleCollectionDateModal extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let date = Chronos.now().getDate();

        this.initState({
            date
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.siteId !== prevProps.siteId ||
            this.props.frequencyId !== prevProps.frequencyId ||
            this.props.name !== prevProps.name ||
            this.props.date !== prevProps.date
        ) {
            let date = Chronos.now().getDate();
            if (this.props.date !== undefined && this.props.date !== null) {
                date = this.props.date;
            }

            this.setState({
                siteId : this.props.siteId,
                frequencyId : this.props.frequencyId,
                name : this.props.name,
                date
            });
        }
    }

    onDateSelected = (date) => {
        this.setState({
            date
        });
    }

    dispatchCallback = (result) => {
        if (this.props.callback !== undefined) {
            this.props.callback(result);
        }
    }

    submitDateOverNetwork = () => {
        if (this.state.dateNetworkInFlight) return;
        this.setState({
            dateNetworkInFlight : true
        });

        console.log(this.state.date);
        console.log(Chronos.with(this.state.date).seconds());

        let formData = new FormData();
        formData.append("siteId", this.state.siteId);
        formData.append("wasteProcedureScheduleFrequencyId", this.state.frequencyId);
        formData.append("name", this.state.name);
        formData.append("lastCollection", Chronos.with(this.state.date).seconds());

        Axios.post(ENDPOINTS.wasteProcedure.setWasteProcedureLastCollection, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.dispatchCallback(true);
                } else {
                    this.props.showModal("Error", API.formatError(resp));
                }

                this.setState({
                    dateNetworkInFlight : false
                });
            })
            .catch((e) => {
                this.setState({
                    dateNetworkInFlight : false
                });

                console.log(e);
                this.props.showModal("Error", "An unknown error has occurred. Please reload the page. [D8500]");
            });
    }

    render() {
        if (!this.props.shown) return [];

        let buttonArea = (<button className={"btn btn-success disabled"}>Saving...</button>);
        if (!this.state.dateNetworkInFlight) {
            buttonArea = (<button className={"btn btn-success"} onClick={() => this.submitDateOverNetwork()}>Save</button>);
        }

        return (
            <div className={"waste-schedule-date-upload-modal-component modal"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header"}>
                            First Collection Date
                            <button type="button" className="close" aria-label="Close" onClick={() => this.dispatchCallback()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className={"modal-body"}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <label>First Collection Date</label>
                                    <DatePicker
                                        className={"form-control"}
                                        selected={this.state.date}
                                        onChange={(date) => this.onDateSelected(date)}
                                        dateFormat={"dd/MM/yyyy"}
                                        maxDate={Chronos.now().getDate()} />
                                </div>
                            </div>
                        </div>

                        <div className={"modal-footer"}>
                            {buttonArea}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}