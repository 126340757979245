import React from 'react';

import "./LoadingSpinner.css";
import hourglass from "../../assets/europackaging/hourglass_empty.svg";

export default class LoadingSpinner extends React.Component {

    render() {
        let classExtra = "";
        if (this.props.inline !== undefined) {
            if (this.props.inline) {
                classExtra = " inline-block";
            }
        }

        return (
            <div className={"loading-spinner-component" + classExtra}>
                <div className={"loading-spinner"} style={{backgroundImage : "url(" + hourglass + ")"}} />
            </div>
        )
    }
}