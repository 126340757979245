import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import { v4 as uuidv4 } from "uuid";

import deleteIcon from "../../../assets/europackaging/trash_white.svg";
import mediaDocument from "../../../assets/europackaging/media_type_pdf.svg";
import mediaAudio from "../../../assets/europackaging/media_type_audio.svg";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../common/ToastManagerComponent";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";
import UploadProgressComponent from "../../app/common/UploadProgressComponent";
import {WasteProcedure} from "../../../data/WasteProcedure";

import "./WasteProcedureEditorComponent.css";

export default class WasteProcedureEditorComponent extends BaseComponent {

    inputFile = null;
    currentlyRequestingMediaTypeId = -1;

    deletedMediaIds = [];

    uploadFiles = [];

    isAdded = false;

    constructor(props, context) {
        super(props, context);

        let wasteProcedureId = this.getUriProperty("id", null);
        let isNewProcedure = false;
        if (wasteProcedureId != null) {
            if (wasteProcedureId === "new") {
                wasteProcedureId = null;
                isNewProcedure = true;
            }
        }

        this.initState({
            procedureNetworkInFlight : false,
            procedureSubmitNetworkInFlight : false,
            wasteProcedure : null,
            media : [],
            wasteProcedureId,
            isNewProcedure,
            progressTitle : "",
            progressMessage : "",
            progressProgress : 0
        });

        this.inputFile = React.createRef();
    }

    componentDidMount() {
        this.isAdded = true;

        if (this.state.wasteProcedureId != null) {
            this.fetchWasteProcedureFromNetwork();
        }
    }

    componentWillUnmount() {
        this.isAdded = false;
    }

    createMediaObject = (mediaTypeId, mediaPath, title, file) => {
        return {
            localId : uuidv4(),
            wasteProcedureMediaTypeId : mediaTypeId,
            mediaPath : mediaPath,
            title : title,
            file : file
        };
    }

    requestMediaFile = (mediaTypeId) => {
        this.currentlyRequestingMediaTypeId = mediaTypeId;

        this.inputFile.current.click();
    }

    fileHadChanged = () => {
        if (this.inputFile.current.files.length > 0) {
            let file = this.inputFile.current.files[0];
            let media = this.state.media;
            media.push(this.createMediaObject(this.currentlyRequestingMediaTypeId, file.name, file.name, file));

            this.setState({
                media
            });
        }

        this.inputFile.current.value = null;
    }

    removeMedia = (mediaItem) => {
        let media = this.state.media;

        for (let i = 0; i < media.length; i++) {
            if ((mediaItem.id !== undefined && media[i].id === mediaItem.id) || (mediaItem.localId !== undefined && media[i].localId === mediaItem.localId)) {
                media.splice(i, 1);
            }
        }

        if (mediaItem.id !== undefined) {
            this.deletedMediaIds.push(mediaItem.id);
        }

        this.setState({
            media
        });
    }

    lookupYouTubeVideoWasPressed = () => {
        let videoId = this.parseYouTubeUrl(this.state.mediaVideoUrl);

        if (videoId != null) {
            let media = this.state.media;
            media.push(this.createMediaObject(WasteProcedure.MEDIA_TYPES.VIDEO, videoId, ""));

            this.setState({
                mediaVideoUrl : "",
                media
            });
        } else {
            this.showError("The provided video URL is not supported. Please provide a different URL.");
        }
    }

    parseYouTubeUrl = (urlString) => {
        // https://youtu.be/UdfSrJvqY_E
        // https://www.youtube.com/watch?v=UdfSrJvqY_E
        // https://youtube.com/shorts/VXYvdWfkJMc
        if (urlString !== undefined && urlString != null) {
            let url = new URL(urlString);
            let pathParts = url.pathname.split("/");
            if (pathParts.length > 0) {
                if (url.hostname.includes("youtu.be")) {
                    return pathParts[pathParts.length - 1];
                } else if (pathParts.length > 2 && pathParts[1] === "shorts") {
                    return pathParts[2];
                } else {
                    let videoId = url.searchParams.get("v");
                    if (videoId !== undefined && videoId != null) {
                        return videoId;
                    }
                }
            }
        }
        return null;
    }

    populateWasteProcedureIntoState = (wasteProcedure) => {
        let wasteProcedureTitle = null;
        let media = [];
        this.deletedMediaIds = [];

        if (wasteProcedure != null) {
            wasteProcedureTitle = wasteProcedure.title;

            if (wasteProcedure.media !== undefined) {
                media = wasteProcedure.media;
            }
        }

        this.setState({
            wasteProcedure,
            media,
            wasteProcedureTitle
        });
    }

    populateUploadFiles = () => {
        this.uploadFiles = [];

        this.state.media.forEach((media) => {
            if (media.localId !== undefined) {
                this.uploadFiles.push(media);
            }
        });
    }

    archiveWasPressed = () => {
        let title = "Archive Waste Procedure";
        let message = "Are you sure you want to archive this Waste Procedure? The Waste Procedure will no longer be available in the app.";
        let buttonLabel = "Archive";
        let buttonClass = "danger";
        if (this.state.wasteProcedure.active == 0) {
            title = "Un-archive Waste Procedure";
            message = "Are you sure you want to un-archive this Waste Procedure? This Waste Procedure will be immediately available in the app.";
            buttonLabel = "Un-archive";
            buttonClass = "success";
        }

        this.props.showModal(title, message, [
            {
                label : buttonLabel,
                className : buttonClass,
                click : () => {
                    this.archiveWasteProcedureOverNetwork();
                    this.props.hideModal();
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.props.hideModal();
                }
            }
        ])
    }

    fetchWasteProcedureFromNetwork = (id) => {
        if (this.state.procedureSpecNetworkInFlight) return;

        if (id === undefined) {
            id = this.state.wasteProcedureId;
        }

        this.setState({
            procedureSpecNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("wasteProcedureId", id);

        Axios.post(ENDPOINTS.wasteProcedure.getWasteProcedure, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateWasteProcedureIntoState(resp.data.wasteProcedure);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    procedureSpecNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error occurred. Please reload the page.");

                this.setState({
                    procedureSpecNetworkInFlight : false
                });
            });
    }

    submitWasteProcedureOverNetwork = () => {
        if (this.state.wasteProcedureNetworkInFlight) return;

        if (this.state.wasteProcedureTitle === "") {
            this.showError("Please provide a Waste Procedure title");
            return;
        }

        this.setState({
            wasteProcedureNetworkInFlight : true,
            progressTitle : "Submitting Waste Procedure",
            progressMessage : "Please wait...",
            progressProgress : 0
        });

        // Make sure upload files are prepared for upload now
        this.populateUploadFiles();

        let formData = new FormData();
        if (this.state.wasteProcedure != null) {
            formData.append("id", this.state.wasteProcedure.id);
        }
        formData.append("title", this.state.wasteProcedureTitle);
        formData.append("deletedMediaIds", JSON.stringify(this.deletedMediaIds));

        Axios.post(ENDPOINTS.wasteProcedure.submitWasteProcedure, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateWasteProcedureIntoState(resp.data.wasteProcedure);

                    this.uploadWasteProcedureMedia(resp.data.wasteProcedure.id,0);
                } else {
                    this.showError(API.formatError(resp));

                    this.setState({
                        wasteProcedureNetworkInFlight : false
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please reload the page. [D8500]")
                this.setState({
                    wasteProcedureNetworkInFlight : false
                });
            })
    }

    uploadWasteProcedureMedia = (wasteProcedureId, index) => {
        if (this.uploadFiles.length > 0 && index < this.uploadFiles.length) {
            this.setState({
                wasteProcedureNetworkInFlight : true,
                progressTitle : "Uploading Media",
                progressMessage : "Uploading file " + (index + 1) + " of " + this.uploadFiles.length
            });

            let uploadFile = this.uploadFiles[index];
            if (uploadFile != null) {
                let config = {
                    onUploadProgress: (progressEvent) => {
                        let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                        // This progress will consider ALL images that need to be uploaded.
                        let progress = (100 * index) + Math.ceil((progressEvent.loaded / progressTotal) * 100) / this.uploadFiles.length;

                        this.setState({
                            progressProgress : progress
                        });
                    }
                }

                let formData = new FormData();
                formData.append("wasteProcedureId", wasteProcedureId);
                formData.append("wasteProcedureMediaTypeId", uploadFile.wasteProcedureMediaTypeId);

                if (uploadFile.wasteProcedureMediaTypeId !== WasteProcedure.MEDIA_TYPES.VIDEO) {
                    formData.append("file", uploadFile.file);
                } else {
                    formData.append("videoId", uploadFile.mediaPath);
                }

                Axios.post(ENDPOINTS.wasteProcedure.uploadWasteProcedureMedia, formData, config)
                    .then((r) => {
                        let resp = API.parse(r);
                        if (resp.success) {
                            if (this.state.wasteProcedure != null) {
                                this.state.wasteProcedure.media = resp.data.media;

                                this.setState({
                                    media : resp.data.media
                                });
                            }
                        } else {
                            Toast.show("Error", API.formatError(resp), Toast.TYPE_ERROR);
                        }

                        this.uploadWasteProcedureMedia(wasteProcedureId, index + 1);
                    })
                    .catch((e) => {
                        console.log(e);
                        Toast.show("Error", "An unknown error has occurred uploading an image", Toast.TYPE_ERROR);
                    });
            } else {
                this.uploadWasteProcedureMedia(wasteProcedureId, index + 1);
            }
        } else {
            this.completeSubmission();
        }
    }

    completeSubmission = () => {
        // Await before dismissing progress to be more natural
        setTimeout(() => {
            if (this.isAdded) {
                this.setState({
                    wasteProcedureNetworkInFlight: false
                });
            }
        }, 400);
    }

    archiveWasteProcedureOverNetwork = () => {
        if (this.state.wasteProcedureNetworkInFlight) return;

        this.setState({
            wasteProcedureNetworkInFlight : true
        });

        let active = (this.state.wasteProcedure.active == 1) ? "0" : "1";

        let formData = new FormData();
        formData.append("wasteProcedureId", this.state.wasteProcedure.id);
        formData.append("active", active);

        Axios.post(ENDPOINTS.wasteProcedure.archiveWasteProcedure, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateWasteProcedureIntoState(resp.data.wasteProcedure);

                    let label = " archived";
                    if (active === "1") {
                        label = " un-archived";
                    }
                    Toast.show("Success", "Waste Procedure successfully " + label, Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    wasteProcedureNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [D3600]");

                this.setState({
                    wasteProcedureNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        let documentElements = [];
        let audioElements = [];
        let videoElements = [];

        this.state.media.forEach((media) => {
            if (media.wasteProcedureMediaTypeId == WasteProcedure.MEDIA_TYPES.PDF) {
                documentElements.push(
                    <div className={"col-6 col-md-3 col-lg-2"}>
                        <div className={"embed-responsive embed-responsive-4by3 media-preview"}>
                            <div className={"file-content"}>
                                <div className={"spacer"} />
                                <div className={"preview-icon"} style={{backgroundImage : "url(" + mediaDocument + ")"}} />
                                <div className={"preview-title"}>{media.title}</div>
                                <div className={"spacer"} />
                            </div>
                            <div className={"remove"} style={{backgroundImage : "url(" + deleteIcon + ")"}} onClick={() => this.removeMedia(media)} />
                        </div>
                    </div>
                )
            } else if (media.wasteProcedureMediaTypeId == WasteProcedure.MEDIA_TYPES.AUDIO) {
                audioElements.push(<div className={"col-6 col-md-3 col-lg-2"}>
                    <div className={"embed-responsive embed-responsive-4by3 media-preview"}>
                        <div className={"file-content"}>
                            <div className={"spacer"} />
                            <div className={"preview-icon"} style={{backgroundImage : "url(" + mediaAudio + ")"}} />
                            <div className={"preview-title"}>{media.title}</div>
                            <div className={"spacer"} />
                        </div>
                        <div className={"remove"} style={{backgroundImage : "url(" + deleteIcon + ")"}} onClick={() => this.removeMedia(media)} />
                    </div>
                </div>);
            } else if (media.wasteProcedureMediaTypeId == WasteProcedure.MEDIA_TYPES.VIDEO) {
                videoElements.push(
                    <div className={"col-6 col-md-4 col-lg-3"}>
                        <div className={"embed-responsive embed-responsive-16by9 media-preview"}>
                            <iframe
                                title={"Video"}
                                src={"https://www.youtube.com/embed/" + media.mediaPath}
                                className={"video-player"}
                                frameBorder="0" />

                            <div className={"remove"} style={{backgroundImage : "url(" + deleteIcon + ")"}} onClick={() => this.removeMedia(media)} />
                        </div>
                    </div>
                )
            }
        });

        if (documentElements.length === 0) {
            documentElements = (
                <div className={"col-12 text-center empty-message"}>No Documents to display</div>
            )
        }

        if (audioElements.length === 0) {
            audioElements = (
                <div className={"col-12 text-center empty-message"}>No Audio to display</div>
            )
        }

        if (videoElements.length === 0) {
            videoElements = (
                <div className={"col-12 text-center empty-message"}>No Videos to display</div>
            )
        }

        let archiveButton = [];
        if (this.state.wasteProcedureId !== null) {
            if (this.state.wasteProcedure != null) {
                if (this.state.wasteProcedure.active == 1) {
                    archiveButton = (
                        <button className={"btn btn-danger"} onClick={this.archiveWasPressed}>Archive</button>);
                } else {
                    archiveButton = (
                        <button className={"btn btn-warning"} onClick={this.archiveWasPressed}>Un-archive</button>);
                }
            }
        }

        return (
            <div className={"container form-inputs waste-procedure-editor-component"}>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <ScreenTitleComponent
                            title={"Waste Procedure"}
                            {...this.props} />
                    </div>
                    <div className={"col-4 screen-actions text-right"}>
                        {archiveButton}
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Waste Procedure Title</label>
                        <input type={"text"} className={"form-control"} name={"wasteProcedureTitle"} value={this.state.wasteProcedureTitle} onChange={this.handleChange} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-6"}>
                        <h3>Documents</h3>
                    </div>

                    <div className={"col-6 screen-actions text-right"}>
                        <button className={"btn btn-primary"} onClick={() => this.requestMediaFile(WasteProcedure.MEDIA_TYPES.PDF)}>Add Document</button>
                    </div>
                </div>

                <div className={"row"}>
                    {documentElements}
                </div>

                <div className={"row"}>
                    <div className={"col-6"}>
                        <h3>Audio</h3>
                    </div>

                    <div className={"col-6 screen-actions text-right"}>
                        <button className={"btn btn-primary"} onClick={() => this.requestMediaFile(WasteProcedure.MEDIA_TYPES.AUDIO)}>Add Audio</button>
                    </div>
                </div>

                <div className={"row"}>
                    {audioElements}
                </div>

                <div className={"row"}>
                    <div className={"col-8"}>
                        <h3>Videos</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs col-md-3"} />
                    <div className={"col-12 col-md-6"}>
                        <div className="input-group">
                            <input type="text" className="form-control" name={"mediaVideoUrl"} value={this.state.mediaVideoUrl} onChange={this.handleChange} placeholder="https://www.youtube.com/?v=abc123" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type={"button"} onClick={this.lookupYouTubeVideoWasPressed}>Add Video</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    {videoElements}
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Audit Areas</h3>
                    </div>
                </div>

                <div className={"row submit-area"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-success"} onClick={this.submitWasteProcedureOverNetwork}>Save Changes</button>
                    </div>
                </div>

                <div className={"file-hide"}>
                    <input type={"file"} ref={this.inputFile} onChange={this.fileHadChanged} />
                </div>

                <UIBlockerComponent
                    shown={this.state.wasteProcedureNetworkInFlight || this.state.configNetworkInFlight} />

                <UploadProgressComponent
                    open={this.state.wasteProcedureNetworkInFlight}
                    title={this.state.progressTitle}
                    message={this.state.progressMessage}
                    progress={this.state.progressProgress} />
            </div>
        )
    }

}