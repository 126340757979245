import React from 'react';
import BaseComponent from "../../BaseComponent";

import "./HeaderBarComponent.css";

import DefaultLogo from "../../../assets/europackaging/ms_logo.png";
import genericLogo from "../../../assets/europackaging/ep_logo.png";
import accountCircle from "../../../assets/europackaging/account_circle.svg";
import alertCircle from "../../../assets/europackaging/alerts_icon.svg";
import {Brand} from "../../../data/Brand";
import {API, ENDPOINTS} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";
import Axios from "axios";
import {NavLink} from "react-router-dom";

export default class HeaderBarComponent extends BaseComponent {

    dataManagerCallback = undefined;

    constructor(props, context) {
        super(props, context);
        this.initState({
            alertCount : 0
        });
    }

    componentDidMount() {
        this.getAlertCountFromNetwork();

        this.dataManagerCallback = (keyName, value) => {
            if (keyName === "site") {
                this.getAlertCountFromNetwork();
            }
        };

        DataManager.addCallback(this.dataManagerCallback);

        // Receive requests to update count
        window._alertCountUpdate = () => {
            console.log("HEADERBAR: ALERTS UPDATE REQUEST");
            this.getAlertCountFromNetwork();
        }
    }

    componentWillUnmount() {
        if (this.dataManagerCallback !== undefined) {
            DataManager.removeCallback(this.dataManagerCallback);
        }

        window._alertCountUpdate = undefined;
    }

    profileActionWasClicked = () => {
        if (this.props.profileCallback !== undefined) {
            this.props.profileCallback();
        }
    }

    alertsWasClicked = () => {
        if (this.props.alertsCallback !== undefined) {
            this.props.alertsCallback();
        }
    }

    getAlertCountFromNetwork = () => {
        if (this.state.alertCountNetworkInFlight) return;

        this.setState({
            alertCountNetworkInFlight : true
        });

        let formData = new FormData();

        let user = DataManager.getUser();

        if (user != null) {
            if (parseInt(user.userTypeId) === API.userTypes.STORE_CHECK) {
                let site = DataManager.getCurrentSite();
                if (site != null) {
                    formData.append("siteId", site.id);
                } else {
                    return;
                }
            }
        }

        Axios.post(ENDPOINTS.alerts.getAlertCount, formData)
            .then((r) => {
                let alertCount = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    alertCount = resp.data.count;
                } else {
                    console.log("SILENT ERROR: " + API.formatError(resp));
                }

                this.setState({
                    alertCountNetworkInFlight : false,
                    alertCount : alertCount
                });
            })
            .catch((e) => {
                console.log("SILENT ERROR: Could not get read count from network. [A0000]");

                this.setState({
                    alertCountNetworkInFlight : false
                });
            });
    }

    render() {
        let useLogo = genericLogo;
        if (parseInt(process.env.REACT_APP_BRAND_ID) === Brand.MS) {
            useLogo = DefaultLogo;
        }

        let alertBadge = [];
        if (this.state.alertCount > 0) {
            alertBadge = (
                <span className={"badge"}>{this.state.alertCount}</span>
            );
        }

        return (
            <div className={"header-bar-component"}>
                <div className={"header-bar"}>
                    <div className={"logo"} style={{backgroundImage : "url(" + useLogo + ")"}}/>
                    <div className={"actions"}>
                        <NavLink to={"/alerts"} className={"action-item"} style={{backgroundImage : "url(" + alertCircle + ")"}}>
                            {alertBadge}
                        </NavLink>

                        <div className={"action-item"} style={{backgroundImage : "url(" + accountCircle + ")"}} onClick={this.profileActionWasClicked} />
                    </div>
                </div>
            </div>
        )
    }

}