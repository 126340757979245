import React from 'react';

import BaseComponent from "../../BaseComponent";
import LoadingSpinner from "../../common/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import "./WasteScheduleUploadModalComponent.css";
import {Toast} from "../common/ToastManagerComponent";

export default class WasteScheduleUploadModalComponent extends BaseComponent {

    inputFile = undefined;

    constructor(props, context) {
        super(props, context);

        this.initState({
            uploadNetworkInFlight : false,
            selectedFileName : "No file selected",
            selectedFile : undefined
        });

        this.inputFile = React.createRef();
    }

    fileSelectWasClicked = () => {
        if (this.inputFile !== undefined) {
            this.inputFile.current.click();
        }
    }

    fileDidChange = (e) => {
        if (e.target.files !== undefined) {
            if (e.target.files.length > 0) {
                let selectedFile = e.target.files[0];
                if (selectedFile !== undefined && selectedFile !== null) {
                    let selectedFileName = selectedFile.name;

                    this.setState({
                        selectedFile,
                        selectedFileName
                    });
                }
            }
        }
    }

    dispatchCallback = (result) => {
        if (this.state.uploadNetworkInFlight) return;

        console.log("CALLBACK");
        console.log(this.props.callback);
        if (this.props.callback !== undefined) {
            console.log("TRIGGER CALLBACK");
            this.props.callback(result);
        }
    }

    uploadScheduleOverNetwork = () => {
        if (this.state.uploadNetworkInFlight) return;
        if (this.state.selectedFile === undefined || this.state.selectedTab === null) return;

        this.setState({
            uploadNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("file", this.state.selectedFile);

        Axios.post(ENDPOINTS.wasteProcedure.uploadWasteProcedureSchedule, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.setState({
                        uploadNetworkInFlight : false
                    }, () => {
                        this.dispatchCallback(true);
                    });

                    Toast.show("Success", "Successfully uploaded Waste Schedule", Toast.TYPE_SUCCESS);
                } else {
                    Toast.show("Error", API.formatError(resp), Toast.TYPE_ERROR);

                    this.setState({
                        uploadNetworkInFlight : false
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                Toast.show("Error", "An unknown error has occurred. Schedules may not have uploaded. Please reload the page. [D8702]", Toast.TYPE_ERROR);

                this.setState({
                    uploadNetworkInFlight : false
                })
            });
    }

    render() {
        if (!this.props.shown) return [];

        let mainContent = [];
        let buttonArea = [];
        if (!this.state.uploadNetworkInFlight) {
            let submitButton = (<button className={"btn btn-success disabled"}>Upload</button>);
            if (this.state.selectedFile !== undefined) {
                submitButton = (<button className={"btn btn-success"} onClick={this.uploadScheduleOverNetwork}>Upload</button>)
            }

            buttonArea = (
                <div>
                    {submitButton}
                    <button className={"btn btn-primary"} onClick={() => this.dispatchCallback(undefined)}>Close</button>
                </div>
            );

            mainContent = (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Upload a file</h3>
                        <p>Please provide your Waste Schedule Excel file.</p>
                        <p className={"text-center"}>
                            <span>{this.state.selectedFileName}</span><br />
                            <button className={"btn btn-primary"} onClick={this.fileSelectWasClicked}>Select File</button>
                        </p>
                        <div className={"input-hide"}>
                            <input type={"file"} ref={this.inputFile} onChange={this.fileDidChange} />
                        </div>
                    </div>
                </div>
            );
        } else {
            mainContent = (
                <div className={"row text-center"}>
                    <div className={"col-12"}>
                        <h3>Uploading...</h3>
                        <p>Your file is uploading and processing, please wait. This may take a minute or two.</p>
                        <p>
                            <LoadingSpinner inline={true} />
                        </p>
                    </div>
                </div>
            )
        }

        return (
            <div className={"waste-schedule-upload-modal-component modal"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header"}>
                            Upload Waste Schedule
                            <button type="button" className="close" aria-label="Close" onClick={() => this.dispatchCallback()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className={"modal-body"}>
                            {mainContent}
                        </div>

                        <div className={"modal-footer"}>
                            {buttonArea}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}