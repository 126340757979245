import BaseComponent from "../../BaseComponent";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import {Chronos} from "../../../data/Chronos";
import React from "react";
import WasteScheduleCollectionDateModal from "../wasteprocedures/WasteScheduleCollectionDateModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import "./SiteConfigurationWasteSchedulesComponent.css";
import {Toast} from "../common/ToastManagerComponent";

export default class SiteConfigurationWasteSchedulesComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let site = this.props.site;

        this.initState({
            site,
            wasteSchedules : [],
            schedulePage : 1,
            scheduleTotal : 0,
            scheduleLimit : 10,
            showDateModal : false,
            dateSiteId : undefined,
            dateFrequencyId : undefined,
            dateName : undefined,
            dateDate : undefined
        });
    }

    componentDidMount() {
        if (this.props.site !== undefined) {
            this.fetchWasteSchedulesFromNetwork();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.site !== prevProps.site) {
            if (this.props.site !== undefined) {
                this.fetchWasteSchedulesFromNetwork();
            }
        }
    }

    schedulePageDidChange = (page) => {
        this.setState({
            schedulePage : page
        }, () => {
            this.fetchWasteSchedulesFromNetwork();
        });
    }

    dateModalDidReturn = (result) => {
        if (result) {
            this.fetchWasteSchedulesFromNetwork();
        }

        this.dismissDateModal();
    }

    summonDateModal = (siteId, frequencyId, name, date) => {
        this.setState({
            dateSiteId : siteId,
            dateFrequencyId : frequencyId,
            dateName : name,
            dateDate : date,
            showDateModal : true
        })
    }

    dismissDateModal = () => {
        this.setState({
            showDateModal : false
        })
    }

    scheduleTableRowWasClicked = (row) => {
        let date = null;
        if (row.lastCollection != null) {
            date = Chronos.withTimestampSeconds(row.lastCollection).getDate();
        }

        this.summonDateModal(row.siteId, row.wasteProcedureScheduleFrequencyId, row.name, date);
    }

    fetchWasteSchedulesFromNetwork = () => {
        if (this.state.scheduleNetworkInFlight) return;
        this.setState({
            scheduleNetworkInFlight : true
        });

        let formData = new FormData();
        if (this.props.site !== undefined) {
            formData.append("siteId", this.props.site.id);
        }
        formData.append("page", this.state.schedulePage);

        Axios.post(ENDPOINTS.wasteProcedure.getWasteProcedureSchedules, formData)
            .then((r) => {
                let wasteSchedules = [];
                let scheduleTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    wasteSchedules = resp.data.data;
                    scheduleTotal = resp.data.count;
                } else {
                    Toast.show("Error", API.parse(resp), Toast.TYPE_ERROR);
                }

                this.setState({
                    scheduleNetworkInFlight : false,
                    wasteSchedules,
                    scheduleTotal
                });
            })
            .catch((e) => {
                Toast.show("Error", "Could not fetch Waste Schedules. Please reload the page and try again. [D8600]", Toast.TYPE_ERROR);
            });
    }

    render() {

        return (
            <div className={"site-configuration-waste-schedules-component container"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"row-mt-2"}>
                            <div className={"col-12"}>
                                <TableComponent
                                    className={"alternating clickable"}
                                    onRowClick={this.scheduleTableRowWasClicked}
                                    data={this.state.wasteSchedules}>
                                    <Column name={"siteName"} title={"Site"} />
                                    <Column name={"equipmentName"} title={"Equipment"} />
                                    <Column name={"name"} title={"Waste"} />
                                    <Column name={"containerQuantity"} title={"Container Qty"} className={"text-center"} />
                                    <Column name={"frequencyName"} title={"Frequency"} />
                                    <Column name={"mon"} title={"Mon"} className={"text-center"} />
                                    <Column name={"tue"} title={"Tue"} className={"text-center"} />
                                    <Column name={"wed"} title={"Wed"} className={"text-center"} />
                                    <Column name={"thu"} title={"Thu"} className={"text-center"} />
                                    <Column name={"fri"} title={"Fri"} className={"text-center"} />
                                    <Column name={"sat"} title={"Sat"} className={"text-center"} />
                                    <Column name={"sun"} title={"Sun"} className={"text-center"} />
                                    <Column name={"weeklyLiftQuantity"} title={"Weekly Lift Qty"} className={"text-center"} />
                                    <Column name={"lastCollection"} title={"First Collection"} className={"text-center"} render={(data, row) => {
                                        let label = "Not Set";
                                        if (data !== null) {
                                            label = Chronos.withTimestampSeconds(data).format("dd.MM.yyyy");
                                        }

                                        return (<span>{label}</span>)
                                    }} />
                                </TableComponent>

                                <div className={"form-block ep-table-paginate-container"}>
                                    <div className={"push"} />
                                    <TablePaginator
                                        page={this.state.schedulePage}
                                        totalCount={this.state.scheduleTotal}
                                        pageSize={this.state.scheduleLimit}
                                        onClick={(page) => this.schedulePageDidChange(page)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <WasteScheduleCollectionDateModal
                    shown={this.state.showDateModal}
                    callback={this.dateModalDidReturn}
                    siteId={this.state.dateSiteId}
                    frequencyId={this.state.dateFrequencyId}
                    name={this.state.dateName}
                    date={this.state.dateDate} />
            </div>
        )
    }
}