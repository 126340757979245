import axios from 'axios'

const CLIENT_ID_APP = "64222f48-73d1-4137-a057-a47389faec53";
const CLIENT_ID_DASHBOARD = "74908045-9767-4b9b-b319-712f9b7e2e31";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const API = (function(axios) {
    return {
        setAuthenticationToken : (token) => {
            if (token !== undefined && token !== null) {
                axios.defaults.headers.common["authtoken"] = token;
            } else if (token === null) {
                delete axios.defaults.headers.common["authtoken"];
            }
        },
        setClientId : (clientId) => {
            axios.defaults.headers.common["clientid"] = clientId;
        },
        onAuthFailed : (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    } else {
                        return status;
                    }
                }
            }
        },
        parse : (resp) => {
            var out = {
                success : false,
                data : null,
                error : "An unknown error has occurred"
            };

            if (resp != null) {
                out.success = resp.data.success;
                out.error = resp.data.error;
                out.data = resp.data.data;
            }

            return out;
        },
        formatError : resp => {
            if (!resp.success) {
                return resp.error.desc + " [" + resp.error.code + "]";
            }
            return "";
        },
        objectToPostData : (object) => {
            let keys = Object.keys(object);
            let formData = new FormData();

            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];
                let value = object[key];
                if (typeof value === 'object' && value !== null) {
                    value = JSON.stringify(value);
                }

                formData.append(key, value);
            }

            return formData;
        },
        getClientId : () => {
            // if (process.env.REACT_APP_CLIENT_TYPE === "app") {
            //     return CLIENT_ID_APP;
            // } else {
            //     return CLIENT_ID_DASHBOARD;
            // }
            return axios.defaults.headers.common["clientid"];
        },
        getAPIUrl : (path, includeAuthToken) => {
            let out = process.env.REACT_APP_BASE_URL + path;
            if (includeAuthToken) {
                out = API.appendAuthTokenToUrl(out);
            }
            return out;
        },
        appendAuthTokenToUrl : (url) => {
            let suffix = {
                at : escape(axios.defaults.headers.common["authtoken"])
            };
            return url + API.createQueryString(suffix);
        },
        createQueryString : (data) => {
            let out = "";
            if (data !== undefined && data != null) {
                for (const [key, value] of Object.entries(data)) {
                    if (out === "") {
                        out = "?"
                    } else {
                        out += "&"
                    }

                    out += key + "=" + escape(value);
                }
            }
            return out;
        },
        clientTypes : {
            APP : CLIENT_ID_APP,
            DASHBOARD : CLIENT_ID_DASHBOARD
        },
        userTypes : {
            ADMINISTRATOR : 1,
            STORE_CHECK : 2,
            AUDIT_TEAM : 3,
            CONTRACTOR : 4,
            COVID19 : 5,
            CONTRACTOR_MANAGER : 6
        },
        batchType : {
            ASSIGN : 1,
            REMOVE : 2
        }
    }

})(axios);

export const ENDPOINTS = {
    auth : {
        login : "auth/login",
        createTemporarySession : "auth/createTemporarySession",
        requestForgottenPassword : "auth/requestForgottenPassword",
        submitForgottenPassword : "auth/submitForgottenPassword"
    },
    user : {
        getUserSites : "user/getUserSites",
        setUserSite : "user/setUserSite",
        getUsersAdmin : "user/getUsersAdmin",
        getUser : "user/getUser",
        getUserEditorData : "user/getUserEditorData",
        submitUser : "user/submitUser",
        setUserPassword : "user/setUserPassword",
        copyUserSites : "user/copyUserSites",
        deleteUser : "user/deleteUser"
    },
    audit : {
        getSiteAreas : "audit/getSiteAreas",
        getIncompleteAudit : "audit/getIncompleteAudit",
        discardAudit : "audit/discardAudit",
        createAudit : "audit/createAudit",
        submitAuditAnswer : "audit/submitAuditAnswer",
        uploadAuditAnswerImage : "audit/uploadAuditAnswerImage",
        getCleaningSpecifications : "audit/getCleaningSpecifications",
        getQuestions : "audit/getQuestions",
        getAnswer : "audit/getAuditSubmissionAnswer",
        finaliseAuditSubmission : "audit/finaliseAuditSubmission",
        getSiteAuditReportData : "audit/getSiteAuditReportData",
        getLastAudit : "audit/getLastAudit",
        getAuditQuestionsAdmin : "audit/getAuditQuestionsAdmin",
        getAuditQuestionConfigurationData : "audit/getAuditQuestionConfigurationData",
        submitAuditQuestionConfiguration : "audit/submitAuditQuestionConfiguration",
        getQuestionAdmin : "audit/getQuestionAdmin",
        submitAuditQuestion : "audit/submitAuditQuestion",
        submitAuditQuestionOutcomeImage : "audit/submitAuditQuestionOutcomeImage",
        archiveAuditQuestion : "audit/archiveAuditQuestion",
        getSiteStatisticsAuditReportData : "audit/getSiteStatisticsAuditReportData",
        getAuditAnswerTableData : "audit/getAuditAnswerTableData",
        exportAuditSubmission : "audit/exportAuditSubmission",
        setAuditSubmissionInactive : "audit/setAuditSubmissionInactive",
        exportLatestAuditSubmission : "audit/exportLatestAuditSubmission",
        getAuditTypes : "audit/getAuditTypes"
    },
    cleaningSpec : {
        getCleaningSpecifications : "cleaningSpec/getCleaningSpecifications",
        getCleaningSpecification : "cleaningSpec/getCleaningSpecification",
        getSpecificationTableData : "cleaningSpec/getSpecificationTableData",
        getSpecificationConfigurationTable : "cleaningSpec/getSpecificationConfigurationTable",
        submitCleaningSpecificationConfiguration : "cleaningSpec/submitCleaningSpecificationConfiguration",
        getCleaningSpecificationAdmin : "cleaningSpec/getCleaningSpecificationAdmin",
        submitCleaningSpecification : "cleaningSpec/submitCleaningSpecification",
        uploadCleaningSpecificationMedia : "cleaningSpec/uploadCleaningSpecificationMedia",
        archiveCleaningSpecification : "cleaningSpec/archiveCleaningSpecification"
    },
    wasteProcedure : {
        getWasteProcedures : "wasteProcedure/getWasteProcedures",
        getWasteProcedure : "wasteProcedure/getWasteProcedure",
        getWasteProceduresAdmin : "wasteProcedure/getWasteProcedureTableData",
        submitWasteProcedure : "wasteProcedure/submitWasteProcedure",
        uploadWasteProcedureMedia : "wasteProcedure/uploadWasteProcedureMedia",
        archiveWasteProcedure : "wasteProcedure/archiveWasteProcedure",
        uploadWasteProcedureSchedule : "wasteProcedure/uploadWasteProcedureSchedule",
        getSiteWasteScheduleNotifications : "wasteProcedure/getSiteWasteScheduleNotifications",
        getWasteProcedureSchedules : "wasteProcedure/getWasteProcedureSchedules",
        setWasteProcedureLastCollection : "wasteProcedure/setWasteProcedureLastCollection"
    },
    alerts : {
        getAlerts : "alert/getAlerts",
        getAlert : "alert/getAlert",
        readAlert : "alert/readAlert",
        deleteAlert : "alert/deleteAlert",
        getAlertCount : "alert/getAlertCount",
        submitAlert : "alert/submitAlert",
        uploadAlertImage : "alert/uploadAlertImage"
    },
    site : {
        getSite : "site/getSite",
        submitSite : "site/submitSite",
        getSiteConfigData : "site/getSiteConfigData",
        getAdminSites : "site/getAdminSites",
        getSiteSiteAreas : "site/getSiteSiteAreas",
        submitSiteSiteAreas : "site/submitSiteSiteAreas",
        searchSites : "site/searchSites",
        getSiteAreaTableData : "site/getSiteAreaTableData",
        getSiteArea : "site/getSiteArea",
        submitSiteArea : "site/submitSiteArea",
        uploadSiteAreaImage : "site/uploadSiteAreaImage",
        getEstateReportSummaryTableData : "site/getEstateReportSummaryTableData",
        exportEstateReportData : "site/exportEstateReportData",
        submitAdminSiteConfiguration : "site/submitAdminSiteConfiguration",
        getSiteSummaryExport : "site/getSiteSummaryExport",
        batchAssignSiteArea : "site/batchAssignSiteArea",
        getMachines : "site/getMachines",
        getContractorSummaryTableData : "site/getContractorSummaryTableData",
        getContractorSummaryChartData : "site/getContractorSummaryChartData",
        getContractorSiteTableData : "site/getContractorSiteTableData",
        getContractorSiteChartData : "site/getContractorSiteChartData",
        getContractorQuestionMetricsData : "site/getContractorQuestionMetricsData",
        getContractorsForCurrentUser : "site/getContractorsForCurrentUser",
        getContractorSiteAnswerReportTableData : "site/getContractorSiteAnswerReportTableData",
        getContractorRegionalTableData : "site/getContractorRegionalTableData",
        getContractorRegionalChartData : "site/getContractorRegionalChartData"
    },
    contractorHours : {
        getContractorHourTableData : "contractorHour/getContractorHourTableData"
    },
    config : {
        getConfig : "config/getConfig",
        setConfig : "config/setConfig"
    }
}
