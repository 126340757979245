import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import SiteConfigurationEditorDetailsComponent from "./SiteConfigurationEditorDetailsComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";

import "./SiteConfigurationEditorComponent.css";

import SiteConfigurationAuditAreasComponent from "./SiteConfigurationAuditAreasComponent";
import SiteConfigurationAlertComponent from "./SiteConfigurationAlertComponent";
import {Toast} from "../common/ToastManagerComponent";
import SiteConfigurationStatisticsComponent from "./SiteConfigurationStatisticsComponent";
import {DataManager} from "../../../data/DataManager";
import SiteConfigurationWasteSchedulesComponent from "./SiteConfigurationWasteSchedulesComponent";

export default class SiteConfigurationEditorComponent extends BaseComponent {

    TAB_SITE_DETAILS = 1;
    TAB_SITE_STATS = 2;
    TAB_AUDIT_AREAS = 3;
    TAB_AUDIT_ALERTS = 4;
    TAB_WASTE_SCHEDULES = 5;

    navTabs = [
        {
            id : this.TAB_SITE_DETAILS,
            label : "Asset Details",
            userVisibility : [
                API.userTypes.CONTRACTOR,
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            id : this.TAB_SITE_STATS,
            label : "Asset Statistics",
            userVisibility : [
                API.userTypes.CONTRACTOR,
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            id : this.TAB_AUDIT_AREAS,
            label : "Audit Areas",
            userVisibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            id : this.TAB_AUDIT_ALERTS,
            label : "Alerts",
            userVisibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            id : this.TAB_WASTE_SCHEDULES,
            label : "Waste Schedules",
            userVisibility: [
                API.userTypes.ADMINISTRATOR
            ]
        }
    ];

    constructor(props, context) {
        super(props, context);

        let siteId = this.getUriProperty("id", null);
        if (siteId === null) {
            let site = DataManager.getCurrentSite();
            if (site !== null) {
                siteId = site.id;
            }
        }

        let isNewSite = false;
        if (API.getClientId() === API.clientTypes.DASHBOARD) {
            if (siteId === "new") {
                isNewSite = true;
            }
        }

        let user = DataManager.getUser();

        this.initState({
            siteNetworkInFlight : false,
            selectedTab : this.TAB_SITE_DETAILS,
            siteId,
            isNewSite,
            user
        });
    }

    componentDidMount() {
        if (!this.state.isNewSite) {
            this.getSiteFromNetwork();
        }

        this.tabSelectionDidChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let currentTab = this.getUriProperty("section", null);
        let previousTab = this.getUriProperty("section", null, prevProps);

        let shouldUpdate = true;
        if (currentTab === previousTab) {
            shouldUpdate = false;
        }

        if (shouldUpdate) {
            this.tabSelectionDidChange();
        }

        if (API.getClientId() === API.clientTypes.DASHBOARD) {
            let currentId = this.getUriProperty("id", null);
            let previousId = this.getUriProperty("id", null);
            if (currentId !== previousId) {
                this.setState({
                    siteId: currentId
                });

                setTimeout(() => {
                    this.getSiteFromNetwork();
                }, 50);
            }
        }
    }

    tabSelectionDidChange = () => {
        let tab = this.TAB_SITE_DETAILS;
        if (!this.state.isNewSite) {
            let selection = this.getUriProperty("section", null);
            if (selection != null) {
                if (selection === "stats") {
                    tab = this.TAB_SITE_STATS;
                } else if (selection === "areas") {
                    tab = this.TAB_AUDIT_AREAS;
                } else if (selection === "alerts") {
                    tab = this.TAB_AUDIT_ALERTS;
                } else if (selection === "waste-schedules") {
                    tab = this.TAB_WASTE_SCHEDULES;
                }
            }
        }

        this.setState({
            selectedTab : tab
        });
    }

    getSiteFromNetwork = () => {
        let siteId = this.state.siteId; //this.getUriProperty("id", null);
        if (siteId == null || siteId === "new") return;

        if (this.state.siteNetworkInFlight) return;

        this.setState({
            siteNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("siteId", siteId);

        Axios.post(ENDPOINTS.site.getSite, formData)
            .then((r) => {
                let site = null;

                let resp = API.parse(r);
                if (resp.success) {
                    site = resp.data.site;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    siteNetworkInFlight : false,
                    site
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please reload the page. [D800]")
            });
    }

    submitSiteOverNetwork = (site) => {
        if (this.state.siteNetworkInFlight) return;

        this.setState({
            siteNetworkInFlight : true
        });

        Axios.post(ENDPOINTS.site.submitSite, API.objectToPostData(site))
            .then((r) => {
                let site = this.state.site;
                let isNewSite = this.state.isNewSite;

                let resp = API.parse(r);
                if (resp.success) {
                    site = resp.data.site;
                    isNewSite = false;

                    Toast.show("Success", "Site has been updated", Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    siteNetworkInFlight : false,
                    site,
                    isNewSite
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please reload the page. [D810]");

                this.setState({
                    siteNetworkInFlight : false
                });
            })
    }

    changeTab = (id) => {
        if (this.state.isNewSite) return;

        let siteId = this.getUriProperty("id", null);
        let newSection = "";
        if (id === this.TAB_SITE_STATS) {
            newSection = "stats";
        } else if (id === this.TAB_AUDIT_AREAS) {
            newSection = "areas";
        } else if (id === this.TAB_AUDIT_ALERTS) {
            newSection = "alerts";
        } else if (id === this.TAB_WASTE_SCHEDULES) {
            newSection = "waste-schedules";
        }

        let useAlternativeRouting = false;
        if (this.props.hasOwnProperty("useAltRouting")) {
            useAlternativeRouting = this.props.useAltRouting;
        }

        if (!useAlternativeRouting) {
            this.props.history.push("/sites/" + siteId + "/" + newSection);
        } else {
            this.props.history.push("/site-details/" + newSection);
        }
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        let contentDetail = [];
        if (this.state.selectedTab === this.TAB_SITE_DETAILS || this.state.isNewSite) {
            contentDetail = (
                <SiteConfigurationEditorDetailsComponent
                    site={this.state.site}
                    changeCallback={(site) => this.submitSiteOverNetwork(site)}
                    {...this.props} />
            )
        } else if (this.state.selectedTab === this.TAB_SITE_STATS) {
            contentDetail = (
                <SiteConfigurationStatisticsComponent
                    site={this.state.site}
                    {...this.props}  />
            )
        } else if (this.state.selectedTab === this.TAB_AUDIT_AREAS) {
            contentDetail = (
                <SiteConfigurationAuditAreasComponent
                    site={this.state.site}
                    showModal={this.props.showModal}
                    hideModal={this.props.hideModal} />
            )
        } else if (this.state.selectedTab === this.TAB_AUDIT_ALERTS) {
            contentDetail = (
                <SiteConfigurationAlertComponent
                    site={this.state.site}
                    showModal={this.props.showModal}
                    hideModal={this.props.hideModal} />
            )
        } else if (this.state.selectedTab === this.TAB_WASTE_SCHEDULES) {
            contentDetail = (
                <SiteConfigurationWasteSchedulesComponent
                site={this.state.site}
                showModal={this.props.showModal}
                hideModal={this.props.hideModal} />
            )
        }

        let navTabs = [];
        this.navTabs.forEach((item) => {
            let addToView = false;
            item.userVisibility.forEach((userTypeId) => {
                if (parseInt(this.state.user.userTypeId) === userTypeId) {
                    addToView = true
                }
            });

            // Special case for Waste Schedules
            if (item.id === this.TAB_WASTE_SCHEDULES) {
                if (process.env.REACT_APP_SHOW_WASTE_PROCEDURES === undefined || process.env.REACT_APP_SHOW_WASTE_PROCEDURES === "false") {
                    addToView = false;
                }
            }

            if (addToView) {
                navTabs.push(
                    <li className="nav-item">
                        <span className={"nav-link" + ((item.id === this.state.selectedTab) ? " active" : "") + ((this.state.isNewSite) ? " disabled" : "")} onClick={() => this.changeTab(item.id)}>{item.label}</span>
                    </li>
                )
            }
        })

        let screenTitle = "Site Details";
        if (this.state.site !== undefined && this.state.site !== null) {
            screenTitle = this.state.site.name;
        }

        return (
            <div className={"container site-configuration-editor-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent
                            title={screenTitle}
                            backLocation={"/"}
                            {...this.props} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <ul className="nav nav-tabs">
                            {navTabs}
                        </ul>
                    </div>
                </div>

                {contentDetail}

                <UIBlockerComponent
                    shown={this.state.siteNetworkInFlight} />
            </div>
        )
    }
}