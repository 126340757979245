import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import TableComponent, {Column, TablePaginator} from "../../common/TableComponent";
import {WasteProcedure} from "../../../data/WasteProcedure";

import "./WasteProcedureLandingComponent.css";
import WasteScheduleUploadModalComponent from "./WasteScheduleUploadModalComponent";
import React from "react";
import ScreenSearchComponent from "../common/ScreenSearchComponent";
import {Chronos} from "../../../data/Chronos";
import WasteScheduleCollectionDateModal from "./WasteScheduleCollectionDateModal";
import {Toast} from "../common/ToastManagerComponent";

export default class WasteProcedureLandingComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            wasteProceduresNetworkInFlight : false,
            wasteProcedures : [],
            currentPage : 1,
            keyword : "",
            tableTotal : 0,
            tableLimit : 10,
            showUploadModal : false,
            wasteSchedules : [],
            schedulePage : 1,
            scheduleTotal : 0,
            scheduleLimit : 10,
            scheduleKeyword : "",
            showDateModal : false,
        });
    }

    componentDidMount() {
        this.fetchWasteProceduresFromNetwork();
        this.fetchWasteSchedulesFromNetwork();
    }

    findMediaTypeCount = (row, mediaTypeId) => {
        if (row.mediaCounts !== undefined) {
            if (row.mediaCounts[mediaTypeId] !== undefined) {
                return row.mediaCounts[mediaTypeId];
            }
        }
        return 0;
    }

    tableRowWasClicked = (row) => {
        this.moveToEditor(row.id);
    }

    tablePageDidChange = (page) => {
        this.setState({
            currentPage : page
        }, () => {
            this.fetchWasteProceduresFromNetwork();
        });
    }

    schedulePageDidChange = (page) => {
        this.setState({
            schedulePage : page
        }, () => {
            this.fetchWasteSchedulesFromNetwork();
        });
    }

    moveToEditor = (id) => {
        let suffix = "";
        if (id !== undefined) {
            suffix = id;
        }
        this.props.history.push("/waste-procedure/" + suffix);
    }

    fetchWasteProceduresFromNetwork = () => {
        if (this.state.wasteProceduresNetworkInFlight) return;
        this.setState({
            wasteProceduresNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", this.state.currentPage);
        if (this.state.keyword !== "") {
            formData.append("keyword", this.state.keyword);
        }

        Axios.post(ENDPOINTS.wasteProcedure.getWasteProceduresAdmin, formData)
            .then((r) => {
                let tableData = [];
                let tableTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    tableData = resp.data.data;
                    tableTotal = resp.data.count;
                } else {
                    this.props.showModal("Error", API.formatError(resp));
                }

                this.setState({
                    wasteProceduresNetworkInFlight : false,
                    tableData,
                    tableTotal
                });
            })
            .catch((e) => {
                this.props.showModal("Could not load Waste Procedures. Please try again later [D8001]");

                this.setState({
                    wasteProceduresNetworkInFlight: false
                });
            });
    }

    fetchWasteSchedulesFromNetwork = () => {
        if (this.state.scheduleNetworkInFlight) return;
        this.setState({
            scheduleNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("page", this.state.schedulePage);
        if (this.state.scheduleKeyword !== "") {
            formData.append("keyword", this.state.scheduleKeyword);
        }

        Axios.post(ENDPOINTS.wasteProcedure.getWasteProcedureSchedules, formData)
            .then((r) => {
                let wasteSchedules = [];
                let scheduleTotal = 0;

                let resp = API.parse(r);
                if (resp.success) {
                    wasteSchedules = resp.data.data;
                    scheduleTotal = resp.data.count;
                } else {
                    Toast.show("Error", API.parse(resp), Toast.TYPE_ERROR);
                }

                this.setState({
                    scheduleNetworkInFlight : false,
                    wasteSchedules,
                    scheduleTotal
                });
            })
            .catch((e) => {
                Toast.show("Error", "Could not fetch Waste Schedules. Please reload the page and try again. [D8601]")
            });
    }

    summonScheduleUploadDialog = () => {
        this.setState({
            showUploadModal : true
        });
    }

    dismissScheduleUploadDialog = () => {
        console.log("MODAL DISMISS");
        this.setState({
            showUploadModal : false
        });
    }

    uploadModalDidReturn = (result) => {
        console.log("UPLOAD MODAL RETURNED");
        if (result) {
            this.fetchWasteSchedulesFromNetwork();
        }
        this.dismissScheduleUploadDialog();
    }

    scheduleKeywordDidChange = (keyword) => {
        this.setState({
            schedulePage : 1,
            scheduleKeyword : keyword
        }, () => {
            this.fetchWasteSchedulesFromNetwork();
        });
    }

    summonDateModal = (siteId, frequencyId, name, date) => {
        this.setState({
            dateSiteId : siteId,
            dateFrequencyId : frequencyId,
            dateName : name,
            dateDate : date,
            showDateModal : true
        })
    }

    dismissDateModal = () => {
        this.setState({
            showDateModal : false
        })
    }

    dateModalDidReturn = (result) => {
        if (result) {
            this.fetchWasteSchedulesFromNetwork();
        }

        this.dismissDateModal();
    }

    scheduleTableRowWasClicked = (row) => {
        console.log("SHOW MODAL");
        let date = null;
        if (row.lastCollection != null) {
            date = Chronos.withTimestampSeconds(row.lastCollection).getDate();
        }

        this.summonDateModal(row.siteId, row.wasteProcedureScheduleFrequencyId, row.name, date);
    }

    render() {
        return (
            <div className={"container waste-procedure-landing-component"}>
                <div className={"row"}>
                    <div className={"col-6"}>
                        <ScreenTitleComponent
                            title={"Waste Procedures"}
                            showBack={false} />
                    </div>
                    <div className={"col-6 text-right screen-actions"}>
                        <button className={"btn btn-primary"} onClick={() => this.moveToEditor("new")}>New Procedure</button>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            data={this.state.tableData}
                            onRowClick={(row) => { this.tableRowWasClicked(row);}}>
                            <Column name={"title"} title={"Waste Procedure"} sortable={false} />
                            <Column name={"id"} title={"PDF"} className={"text-center"} sortable={true} render={(data, row) => {
                                return (
                                    <span>{this.findMediaTypeCount(row, WasteProcedure.MEDIA_TYPES.PDF)}</span>
                                )
                            }} />
                            <Column name={"id"} title={"Audio"} className={"text-center"} sortable={true} render={(data, row) => {
                                return (
                                    <span>{this.findMediaTypeCount(row, WasteProcedure.MEDIA_TYPES.AUDIO)}</span>
                                )
                            }} />
                            <Column name={"id"} title={"Video"} className={"text-center"} sortable={true} render={(data, row) => {
                                return (
                                    <span>{this.findMediaTypeCount(row, WasteProcedure.MEDIA_TYPES.VIDEO)}</span>
                                )
                            }} />
                            <Column name={"id"} title={""}  className={"text-center"} render={(column, row) => {
                                return (
                                    <span className={"btn btn-primary"}>Edit</span>
                                );
                            }} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.currentPage}
                                totalCount={this.state.tableTotal}
                                pageSize={this.state.tableLimit}
                                onClick={(page) => this.tablePageDidChange(page)} />
                        </div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-4"}>
                        <ScreenTitleComponent
                            title={"Waste Schedules"}
                            showBack={false} />
                    </div>
                    <div className={"col-12 col-md-4 screen-actions"}>
                        <ScreenSearchComponent
                            callback={this.scheduleKeywordDidChange} />
                    </div>
                    <div className={"col-12 col-md-4 text-right screen-actions"}>
                        <button className={"btn btn-primary"} onClick={this.summonScheduleUploadDialog}>Upload Schedule</button>
                    </div>
                </div>

                <div className={"row-mt-2"}>
                    <div className={"col-12"}>
                        <TableComponent
                            className={"alternating clickable"}
                            onRowClick={this.scheduleTableRowWasClicked}
                            data={this.state.wasteSchedules}>
                            <Column name={"siteName"} title={"Site"} />
                            <Column name={"equipmentName"} title={"Equipment"} />
                            <Column name={"name"} title={"Waste"} />
                            <Column name={"containerQuantity"} title={"Container Qty"} className={"text-center"} />
                            <Column name={"frequencyName"} title={"Frequency"} />
                            <Column name={"mon"} title={"Mon"} className={"text-center"} />
                            <Column name={"tue"} title={"Tue"} className={"text-center"} />
                            <Column name={"wed"} title={"Wed"} className={"text-center"} />
                            <Column name={"thu"} title={"Thu"} className={"text-center"} />
                            <Column name={"fri"} title={"Fri"} className={"text-center"} />
                            <Column name={"sat"} title={"Sat"} className={"text-center"} />
                            <Column name={"sun"} title={"Sun"} className={"text-center"} />
                            <Column name={"weeklyLiftQuantity"} title={"Weekly Lift Qty"} className={"text-center"} />
                            <Column name={"lastCollection"} title={"First Collection"} className={"text-center"} render={(data, row) => {
                                let label = "Not Set";
                                if (data !== null) {
                                    label = Chronos.withTimestampSeconds(data).format("dd.MM.yyyy");
                                }

                                return (<span>{label}</span>)
                            }} />
                        </TableComponent>

                        <div className={"form-block ep-table-paginate-container"}>
                            <div className={"push"} />
                            <TablePaginator
                                page={this.state.schedulePage}
                                totalCount={this.state.scheduleTotal}
                                pageSize={this.state.scheduleLimit}
                                onClick={(page) => this.schedulePageDidChange(page)} />
                        </div>
                    </div>
                </div>

                <WasteScheduleUploadModalComponent
                    shown={this.state.showUploadModal}
                    callback={this.uploadModalDidReturn} />

                <WasteScheduleCollectionDateModal
                    shown={this.state.showDateModal}
                    callback={this.dateModalDidReturn}
                    siteId={this.state.dateSiteId}
                    frequencyId={this.state.dateFrequencyId}
                    name={this.state.dateName}
                    date={this.state.dateDate} />

            </div>
        );
    }

}