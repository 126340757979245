import React from 'react';
import BaseComponent from "../../BaseComponent";
import {NavLink} from "react-router-dom";

import "./TopBarComponent.css";

import logoImageMS from "../../../assets/europackaging/ms_logo.png";
import logoImageGeneric from "../../../assets/europackaging/ep_logo.png";
import {Brand} from "../../../data/Brand";
import {API} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";

export default class TopBarComponent extends BaseComponent {

    navigationOptions = [
        {
            to : "/",
            label : "Home",
            exact : true,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/sites",
            label : "Sites",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/users",
            label : "Users",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/audits",
            label : "Audits",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/cleaning-specifications",
            label : "Cleaning Specs",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/contractor",
            label : "Contractor",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR,
                API.userTypes.CONTRACTOR_MANAGER
            ]
        }
    ]

    constructor(props, context) {
        super(props, context);

        let user = DataManager.getUser();

        this.initState({
            user
        });
    }

    signOutWasClicked = () => {
        if (this.props.profileCallback !== undefined) {
            this.props.profileCallback();
        }
    }

    render() {
        let useLogo = logoImageGeneric;
        if (parseInt(process.env.REACT_APP_BRAND_ID) === Brand.MS) {
            useLogo = logoImageMS;
        }

        let navigationOptions = [];
        this.navigationOptions.forEach((navItem) => {
            let showOption = true;

            if (navItem.hasOwnProperty("visibility")) {
                showOption = false;
                navItem.visibility.forEach((roleTypeId) => {
                    if (roleTypeId === parseInt(this.state.user.userTypeId)) {
                        showOption = true;
                    }
                })
            }

            if (showOption) {
                navigationOptions.push(
                    <NavLink className={"btn"} activeClassName={"active"} to={navItem.to}
                             exact={navItem.exact}>{navItem.label}</NavLink>
                );
            }
        })

        let wasteProceduresOption = [];
        if (parseInt(this.state.user.userTypeId) === API.userTypes.ADMINISTRATOR) {
            if (process.env.REACT_APP_SHOW_WASTE_PROCEDURES !== undefined) {
                if (process.env.REACT_APP_SHOW_WASTE_PROCEDURES === "true") {
                    wasteProceduresOption = (
                        <NavLink className={"btn"} activeClassName={"active"} to={"/waste-procedures"} exact={true}>Waste
                            Procs</NavLink>
                    )
                }
            }
        }

        return (
            <div className={"top-bar-component"}>
                <div className={"logo"} style={{backgroundImage : "url(" + useLogo + ")"}} />
                <div className={"spacer"} />
                <div className={"actions"}>
                    {navigationOptions}

                    {wasteProceduresOption}

                    <NavLink className={"btn btn-info"} to={"/settings"}>Settings</NavLink>
                </div>
            </div>
        )
    }

}